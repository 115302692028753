import { ButtonProps } from "react-native";
import {
  BookMarked,
  Construction,
  FileStack,
  Icon,
  LucideIcon,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { HorizontalFlex } from "@/components/layout/Flex";
import React from "react";

export const BiDropDownButton: React.FC<{
  label?: React.ReactNode;
  icon?: LucideIcon;
  disabled?: boolean;
  variant?: "ghost" | "default" | "secondary";
  options: {
    icon?: LucideIcon;
    label?: React.ReactNode;
    onClick?: () => void;
  }[];
}> = (props) => {
  let Icon = props.icon;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button disabled={props.disabled} variant={props.variant}>
          <HorizontalFlex gap={2} align={"center"}>
            {Icon && <Icon size={14} />}
            {props.label}
          </HorizontalFlex>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-1 flex-col border-0">
        {props.options.map((opt, i) => {
          let Icon = opt.icon;

          return (
            <DropdownMenuItem key={i}>
              <Button variant={"ghost"} onClick={opt.onClick}>
                <HorizontalFlex align="center" gap={2}>
                  {Icon && <Icon size={14} />}
                  {opt.label}
                </HorizontalFlex>
              </Button>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
