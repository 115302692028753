import { BookingError, BookingErrorLevel } from "@/models/booking";
import { FC } from "react";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { useTranslation } from "react-i18next";
import { BookX } from "lucide-react";

export const BookingErrorDisplay: FC<{
  errors?: BookingError[];
}> = ({ errors }) => {
  const hasDatevBookingsFeature = useFeatureFlag(FeatureFlags.DatevBookings);
  const { t } = useTranslation();

  if (!hasDatevBookingsFeature || !errors || errors.length === 0) return null;

  return (
    <VerticalFlex>
      {errors.map((err) => (
        <HorizontalFlex>
          {err.level == BookingErrorLevel.Warn && (
            <BookX className={"text-yellow-600"} />
          )}
          {err.level == BookingErrorLevel.Error && (
            <BookX className={"text-orange-600"} />
          )}
          <span className={"text-sm text-muted-foreground"}>
            {t("model.bookingError.code." + err.code)}
          </span>
        </HorizontalFlex>
      ))}
    </VerticalFlex>
  );
};
