import { useContext } from "react";
import {
  ModalContext,
  ModalDescription,
} from "@/components/modal/ModalProvider";

export const useModal = () => {
  return useContext(ModalContext);
};

export const useModalCallback = (description: ModalDescription) => {
  let modal = useModal();
  return () => {
    return modal.showModal(description);
  };
};
