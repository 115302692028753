import { SimpleDate } from "@/models/document";

export interface Booking {
  id: string;
  bookingDate: SimpleDate;
  amount: string;
  accountingModel: "SKR03" | "SKR04";
  account: number;
  accountContra: number;
  bookingFlags: BookingFlag;
  label: string;
}

export interface BookingError {
  id: string;
  level: BookingErrorLevel;
  code: string;
  extra?: any;
}

export enum BookingErrorLevel {
  Info = 10,
  Warn = 20,
  Error = 30,
}

export enum BookingFlag {
  V19 = "v19%",
  V9 = "v7%",
  Ust19 = "ust19%",
  Ust7 = "ust7%",
}
