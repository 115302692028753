import { FC } from "react";
import { Booking, BookingError } from "@/models/booking";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import { useTranslation } from "react-i18next";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { formatMoney, formatSimpleDate } from "@/util/format";
import { BookCheck } from "lucide-react";

export const BookingDisplay: FC<{
  bookings?: Booking[];
}> = ({ bookings }) => {
  const hasDatevBookingsFeature = useFeatureFlag(FeatureFlags.DatevBookings);
  const { t } = useTranslation();

  if (!hasDatevBookingsFeature || !bookings || bookings.length === 0)
    return null;

  return (
    <VerticalFlex gap={0} className={"text-sm text-muted-foreground"}>
      {bookings.map((b, i) => (
        <>
          <HorizontalFlex key={"b" + i} className={"mt-2"}>
            <BookCheck className={"mt-2 h-4"} />
            <span>{formatSimpleDate(b.bookingDate)}</span>
            <span>{formatMoney(+b.amount * 100)}</span>
            <span>{b.account}</span>
            <span>{b.accountContra}</span>
            <span className={"h-4 flex-1 overflow-hidden"}>
              <span className={"text-ellipsis"}>{b.label}</span>
            </span>
          </HorizontalFlex>
          {b.bookingFlags && (
            <HorizontalFlex key={"tax" + i} className={"mt-0"}>
              <span className={"ml-10"}>
                + {t("model.booking.bookingFlags." + b.bookingFlags)}
              </span>
            </HorizontalFlex>
          )}
        </>
      ))}
    </VerticalFlex>
  );
};
