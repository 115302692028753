import { useTranslation } from "react-i18next";
import { BookMarked, FileDown, FileStack } from "lucide-react";
import React from "react";
import { BiDropDownButton } from "@/components/button/BiDropDownButton";
import { useModal } from "@/components/modal/useModal";
import { useExportDocumentExportModal } from "@/feature/documents/components/Export/DocumentExportModal";
import { useExportDatevExportModal } from "@/feature/documents/components/Export/DatevExportModal";

export const ExportButton = () => {
  const { t } = useTranslation();
  const showDocumentExport = useExportDocumentExportModal();
  const showDatevExport = useExportDatevExportModal();

  return (
    <BiDropDownButton
      variant={"ghost"}
      label={t("common.export")}
      icon={FileDown}
      options={[
        {
          label: t("component.documents.export.kind.document"),
          icon: FileStack,
          onClick: showDocumentExport,
        },
        {
          label: t("component.documents.export.kind.datev"),
          icon: BookMarked,
          onClick: showDatevExport,
        },
      ]}
    />
  );
};
