import { GLOBAL_API_TOKEN } from "@/api/reduxApi";

export const exportDatevAPI = {
  exportDocuments: (args: { from: string; to: string }) => {
    return (
      process.env.REACT_APP_API_URL +
      "export/documents?from=" +
      encodeURIComponent(args.from ?? "") +
      "&to=" +
      encodeURIComponent(args.to ?? "") +
      "&token=" +
      GLOBAL_API_TOKEN +
      "&note=DoNotShareThisLink"
    );
  },
  exportDatev: (args: {
    from: string;
    to: string;
    documents: boolean;
    bookings: boolean;
    onlyNew: boolean;
  }) => {
    return (
      process.env.REACT_APP_API_URL +
      "export/datev?from=" +
      encodeURIComponent(args.from ?? "") +
      "&to=" +
      encodeURIComponent(args.to ?? "") +
      "&documents=" +
      args.documents +
      "&bookings=" +
      args.bookings +
      "&onlyNew=" +
      args.onlyNew +
      "&token=" +
      GLOBAL_API_TOKEN +
      "&note=DoNotShareThisLink"
    );
  },
};
