import { ReactNode } from "react";
import { cn } from "@/lib/utils";

export const Text = (props: { children: ReactNode; muted?: boolean }) => {
  return (
    <span className={cn("text-base", props.muted && "text-muted-foreground")}>
      {props.children}
    </span>
  );
};
