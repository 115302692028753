import { FC } from "react";
import { Badge } from "@/components/ui/badge";
import { useTranslation } from "react-i18next";
import { BiDocument, documentIsInvalid } from "@/models/document";
import { BankTransaction } from "@/models/bankTransaction";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import { BookX, FileWarning } from "lucide-react";

export const BookingBadge: FC<{
  item: BiDocument | BankTransaction;
}> = ({ item }) => {
  const { t } = useTranslation();

  const isAutomatic = item.match?.autoMatch;

  const hasDatevBookingsFeature = useFeatureFlag(FeatureFlags.DatevBookings);

  if (item) {
    return (
      <div className="relative inline-block">
        {/* These are temporary */}
        {hasDatevBookingsFeature && item.bookingErrors?.length ? (
          <Badge
            className={
              "mr-2 self-start bg-orange-400 font-extrabold text-white"
            }
          >
            {item.bookingErrors.length}
            <BookX className={"h-4 text-white"} />
          </Badge>
        ) : null}
        {documentIsInvalid(item) ? (
          <Badge className={"bg-yellow-400/80 text-center"}>
            {t("model.document.status_invalid")}
          </Badge>
        ) : !item.match ? (
          <Badge className={"bg-gray-400 text-center"}>
            {t("model.document.status_open")}
          </Badge>
        ) : item.match.amountOpen === 0 ? (
          <Badge className={"self-start bg-green-400"}>
            {t("model.document.status_booked")}
          </Badge>
        ) : (
          <Badge className={"self-start bg-orange-400"}>
            {t("model.document.status_partial")}
          </Badge>
        )}
        {isAutomatic && item.match && (
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="absolute right-[-4px] top-[-4px] block h-2 w-2 rounded-full bg-muted-foreground shadow-sm shadow-accent"></span>
            </TooltipTrigger>
            <TooltipContent>
              <p>Das wurde automatisch gematcht. Bitte überprüfen.</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    );
  } else {
    return (
      <Badge className={"self-start bg-orange-400"}>
        {t("model.document.status_partial")}
      </Badge>
    );
  }
};
